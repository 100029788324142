<template>
  <div class="v_task_undone g-flex-column">
    <head-back>
      <template v-slot:returnText>
          <span>返回</span>
        </template>
      <template>
        <span>{{titleName}}</span>
      </template>
    </head-back>

    <div class="v-task-undone-container g-flex-column">
      <van-tabs v-model="form.status" swipeable @change="tabChange" background="#FA436A" color="#FFFFFF" title-inactive-color="#FFFFFF" title-active-color="#FFFFFF">
        <van-tab v-for="(item, index) in tabList" :name="item.name" :key="index" :title="item.title + (item.number ? '('+ item.number + ')' : '')"></van-tab>
        <van-list v-show="orderList.length" class="v-task-undone-list" v-model="loading" :finished="finished"  finished-text="没有更多了"  @load="onLoad" :immediate-check="false">
          <div class="v-task-undone-item" v-for="(item, index) in orderList" :key="index">
            <!-- 头部 -->
            <div class="v-task-undone-item-top g-flex-align-center">
              <!-- 店铺名称 -->
              <div class="v-task-undone-item-top-left g-flex-align-center">
                <i class="iconfont" :class="item.platform.icon"></i>
                <p class="v-task-undone-item-top-left-store">{{ item.userShop.title | filtersStoreName}}</p>
              </div>
              <!-- 订单状态 -->
              <!-- 0已取消  10=待接单  20=待审核  30=操作中 40=待返款 50=待收货 60=待完成 70已完成 -->
              <div class="v-task-undone-item-top-right">
                <span class="v-task-undone-item-top-right-staus" v-show="item.status === 10">待接单</span>
                <span class="v-task-undone-item-top-right-staus" v-show="item.status === 20">待审核</span>
                <span class="v-task-undone-item-top-right-staus" v-show="item.status === 30">操作中</span>
                <span class="v-task-undone-item-top-right-staus" v-show="item.status === 40">待返款</span>
                <span class="v-task-undone-item-top-right-staus" v-show="item.status === 50">待收货</span>
                <span class="v-task-undone-item-top-right-staus" v-show="item.status === 60">待完成</span>
                <span class="v-task-undone-item-top-right-staus" v-show="item.status === 70">已完成</span>
              </div>
            </div>
            <!-- 中间 -->
            <div class="v-task-undone-item-middle g-flex-align-center">
              <!-- 左侧 -->
              <div class="v-task-undone-item-middle-left">
                <img :src="item.img[0]" alt="">
              </div>
              <!-- 右侧 -->
              <div class="v-task-undone-item-middle-right">
                <!-- 商品佣金 -->
                <div class="v-task-undone-item-middle-right-top g-flex-align-center">
                  <!-- 宝贝标题： -->
                  <p class="v-task-undone-item-middle-right-top-name g-flex-align-center"><span>{{item.title | filtersStoreName}}</span> </p>
                  <p class="v-task-undone-item-middle-right-top-yong g-flex-align-end">佣金:<span>¥{{item.buy_price}}</span></p>
                </div>
                <!-- 垫付 -->
                <!-- <div class="v-task-undone-item-middle-right-middle g-flex-justify-end" v-show="item.ben_price">
                  <p class="v-task-undone-item-middle-right-middle-dian g-flex-align-end">垫付: <span>¥{{item.ben_price}}</span></p>
                </div> -->
                <!-- 单数 -->
                <!-- <div class="v-task-undone-item-middle-right-middle g-flex-justify-end g-flex-align-center">
                  <p class="v-task-undone-item-middle-right-top-dan g-flex-align-end">单数:<span>{{item.buy_num}}件</span></p>
                </div> -->
                <!-- 任务类型 -->
                <!-- <div class="v-task-undone-item-middle-right-middle g-flex-justify-end g-flex-align-center">
                  <p class="v-task-undone-item-middle-right-middle-type g-flex-align-end"><span>{{item.task_type.title}}</span></p>
                </div> -->
  
                <!-- 时间 -->
                <div class="v-task-undone-item-middle-right-middle g-flex-justify-end">
                  <p class="v-task-undone-item-middle-right-bottom-time">{{formatDate(item.create_time, 'yyyy-MM-dd hh:mm:ss')}}</p>
                </div>
              </div>
            </div>

            <!-- 线 -->
            <div class="v-task-undone-item-line"></div>

            <!-- 底部 -->
            <div class="v-task-undone-item-bottom">
              <!-- 回收时间 -->
              <p class="v-task-undone-item-bottom-stop-time" v-show="item.status === 10">回收时间：{{formatDate(item.close_time, 'yyyy-MM-dd hh:mm:ss')}} </p>
             
              <!-- 截止时间 -->
              <p class="v-task-undone-item-bottom-stop-time v-task-undone-item-bottom-stop-time-second" v-show="item.status !== 10">截止时间：{{item.countTime}} </p>
             
              <!-- 操作按钮 -->
              <div class="v-task-undone-item-bottom-button g-flex-align-center g-flex-justify-end">
                <!-- 取消订单 -->
                <div class="v-task-undone-item-bottom-button-item g-flex-align-center g-flex-justify-center" @click="cancelOrder(item)" v-show="item.status === 20 || item.status === 30">
                  <span>取消订单</span>
                </div>
                <!-- 取消任务 -->
                <div class="v-task-undone-item-bottom-button-item g-flex-align-center g-flex-justify-center" v-show="item.status === 10" @click="cancelTask(item)">
                  <span>取消任务</span>
                </div>
                <!-- 接受任务 -->
                <div class="v-task-undone-item-bottom-button-item g-flex-align-center g-flex-justify-center" @click="receiveTask(item)" v-show="item.status === 10">
                  <span>接受任务</span>
                </div>

                <!-- 操作任务 -->
                <div class="v-task-undone-item-bottom-button-item v-task-undone-btn-operation g-flex-align-center g-flex-justify-center" v-show="item.status === 30" @click="operaTask(item, 1)" >
                  <span>操作任务</span>
                </div>

                <!-- 查看详情 -->   <!-- 0已取消  10=待接单  20=待审核  30=操作中 40=待返款 50=待收货 60=待完成 70已完成 -->
                <div class="v-task-undone-item-bottom-button-item g-flex-align-center g-flex-justify-center" @click="operaTask(item, 2)" v-show="item.status === 40 || item.status === 50">
                  <span>查看详情</span>
                </div>
                <!-- 确认收货 -->
                <div class="v-task-undone-item-bottom-button-item v-task-undone-btn-operation g-flex-align-center g-flex-justify-center" @click="confirmReceive(item)" v-show="item.status === 50">
                  <span>确认收货</span>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-tabs>

      <NoList v-show="orderList.length == 0"/> 
    </div>

    <!-- 取消订单 -->
    <task-cancel ref="TaskCancel" @emitConfirmCancelOrder="emitConfirmCancelOrder"></task-cancel>

    <!-- 确认弹窗 -->
    <task-confirm-pop ref="TaskConfirmPop" :title="confirmPopTitle" :tips="confirmPopTips" :btnList="popBtnList" @emitConfirmItemClick="emitConfirmItemClick"></task-confirm-pop>
    
    <!-- 任务详情 -->
    <task-details ref="TaskDetails" @emitSaveSuccess="emitSaveSuccess"></task-details>

    <!-- 确认收货上传弹窗 -->
    <task-undone-con-rec-pop @emitConfirmReceive="emitConfirmReceive" ref="TaskUndoneConRecPop"/>
  </div>
</template>

<script>
  import HeadBack from '@/components/HeadBack.vue'
  import { formatDate } from '@/utils/index.js'
  import { PAGE, LIMIT } from '@/utils/constants.js'
  import TaskCancel from '@/components/TaskCancel.vue'
  import TaskConfirmPop from '@/components/TaskConfirmPop.vue'
  import TaskDetails from '@/views/taskliulan/taskdetails/TaskDetails.vue'
  import TaskUndoneConRecPop from './TaskUndoneConRecPop.vue'
  import { apiGetTotalOrder, apiGetOrderList, apiConfirmOrder, apiCancelOrder, apiConfirmReceipt } from '@/utils/api.js'
  export default {
    components: { HeadBack, TaskCancel, TaskConfirmPop, TaskDetails, TaskUndoneConRecPop },
    data() {
      return {
        timer: 0,
        startTime: 0,
        timeEndText: '00:00:00', 

        confirmPopTitle: '',
        confirmPopTips: '',
        popBtnList: [{ name: '关闭', event: 'comfirmPopClose' }, { name: '确认取消', event: 'comfirmPopCancel' }],
        routeType: this.$route.params.type, //0垫付  1浏览  2预售
        num: 10,
        form: {
          type: this.$route.params.type,
          status: 'ALL', //all全部 1待操作 2待返款 3待收货 已完成4 已取消5
          page: PAGE,
          limit: LIMIT,
        },
        loading: false,
        finished: false,

        orderList: [],

        // 总订单数量对象 用于computed s10=待接单  s20=待审核  s30=操作中 s40=待返款 s50=待收货 s60=待完成 s70已完成
        totalTaskObj: { s10: 0, s20: 0, s30: 0, s40: 0, s50: 0, s60: 0, s70: 0 },

        opeaObj: {
          id: '',
          remark: ''
        }

      }
    },
    created() {
        this.apiGetTotalOrderHandel()
        this.apiGetOrderListHandel()
    },
    filters: {
      filtersStoreName(dataStr) {
        let str = ''
        for(let i = 0;i<dataStr.length; i++) {
          if(i % 2 === 0) str+= dataStr.substr(i, 1)
          else if(i % 2 === 1) str = str + '*'
        }
        return str
      }
    },
    methods: {
      formatDate,
      // 操作中提交成功
      async emitSaveSuccess() {
       await this.apiGetTotalOrderHandel()
       this.resetData()
      },
      onLoad() {
        this.form.page++
        this.apiGetOrderListHandel()
      },
      
      // 获取任务列表
      async apiGetOrderListHandel() {
        this.$global.loadingShow = true
        const { success, data } = await apiGetOrderList(this.form)
        if(!success) return
        this.orderList = this.orderList.concat(data.list.map(item => {
          item.task_time = item.task_time * 1000 //处理成毫秒
          item.countTime = ''
          return item
        }))

        this.startTime = data.nowTime * 1000 //开始时间（后台取） 处理成毫秒
        
        this.loading = false
        this.finished = data.list.length < this.form.limit
        
        // 截止时间倒计时处理
        this.countTimeDeal()
        
      },
      // 截止时间倒计时处理
      countTimeDeal() {
        if(this.timer) clearInterval(this.timer)
        this.timer = setInterval(() => {
          this.orderList.forEach((item) => {
            if(Number(item.task_time)) {
              let chazhi = item.task_time - this.startTime //毫秒差值
              if(chazhi > 0 ) {
                let day = Math.floor(chazhi / 86400000)
                let hour = Math.floor((chazhi / 3600000) % 24)
                let min =  Math.floor((chazhi / 60000) % 60)
                let sec =  Math.floor((chazhi / 1000) % 60)
                hour = hour < 10 ? "0" + hour : hour
                min = min < 10 ? "0" + min : min
                sec = sec < 10 ? "0" + sec : sec
                if(day > 0) {
                  item.countTime = `${day}天${hour}小时${min}分${sec}秒`
                } 
                if(day <= 0 && hour > 0 ) {
                  item.countTime = `${hour}小时${min}分${sec}秒`
                }
                if(day <= 0 && hour <= 0) {
                  item.countTime =`${min}分${sec}秒`
                }
                item.task_time = item.task_time - 1000
              }else {
                item.countTime = this.timeEndText
              }
            }else {
              item.countTime = this.timeEndText
            }
          })
        },1000)
      },

      // 获取任务数量 s10=待接单  s20=待审核  s30=操作中 s40=待返款 s50=待收货 s60=待完成 s70已完成
      async apiGetTotalOrderHandel() {
        const { success, data } = await apiGetTotalOrder({ type: this.routeType })
        if(!success) return
        this.totalTaskObj = data.data
      },
      
      // tab标签变化
      tabChange(tab) {
        console.log('当前tab', tab)
        console.log('form.status', this.form.status)
        this.resetData()
      },

      resetData() {
        this.orderList = []
        this.loading = false
        this.finished = false
        this.form.page = 1
        this.apiGetOrderListHandel()
      },



      // 点击接收任务
      receiveTask({ id }) {
        this.opeaObj.id = id
        this.confirmPopTitle = '接受任务'
        this.confirmPopTips = '确认接受此任务？'
        this.popBtnList = [{ name: '关闭', event: 'comfirmPopClose' }, { name: '接收任务', event: 'apiConfirmOrderHandel' }]
        this.$refs.TaskConfirmPop.onShow(true)
      },

      // 确认弹窗触发
      emitConfirmItemClick(event) {
        if(!event) return
        this[event]()
      },

      // 点击取消订单
      cancelOrder({ id }) {
        this.opeaObj.id = id
        this.$refs.TaskCancel.onShow(true)
      },

      // 点击取消任务
      cancelTask({ id }) {
        this.opeaObj.id = id
        this.confirmPopTitle = '取消任务'
        this.confirmPopTips = '确定取消任务'
        this.popBtnList = [{ name: '关闭', event: 'comfirmPopClose' }, { name: '确认取消', event: 'confirmCancelTask' }]
        this.$refs.TaskConfirmPop.onShow(true)
      },

      // 确认取消订单
      emitConfirmCancelOrder(title) {
        this.opeaObj.remark = title
        this.apiCancelOrderHandel()
      },

      // 确认取消任务
      confirmCancelTask() {
        if(this.opeaObj.remark) this.opeaObj.remark = ''
        this.apiCancelOrderHandel()
      },
 
     // 确认取消任务 取消订单请求 取消任务 id remark
      async apiCancelOrderHandel() {
        this.$global.loadingShow = true
        const { success, data } = await apiCancelOrder(this.opeaObj)
        if(!success) return
        this.$toast(data.msg)
        await this.apiGetTotalOrderHandel()
        await this.resetData()
        console.log(data)
      },

      // 确定接受任务 接受任务请求
      async apiConfirmOrderHandel() {
        this.$global.loadingShow = true
        const { success, data } = await apiConfirmOrder(this.opeaObj)
        if(!success) return
        console.log(data)
        await this.resetData()
        await this.apiGetTotalOrderHandel()
      },

      // 取消任务 接受任务 确认关闭弹窗
      comfirmPopClose() {
        this.$refs.TaskConfirmPop.onShow(false)
      },

      // 操作任务 点击查看详情 status 1 操作任务  2查看详情
      operaTask(item, status) {
        console.log('操作任务')
        this.$refs.TaskDetails.onShow(item, status)
      },

      // 点击确认收货 弹窗上传截图
      confirmReceive(item) {
        if(item.screenshot_arrival == 0) return this.apiConfirmReceiptHandel(item)
        console.log(item)
        this.$refs.TaskUndoneConRecPop.onShow(item)
      },

      // 确认收货
      async apiConfirmReceiptHandel(item) {
        this.$global.loadingShow = true
        const { success, data } = await apiConfirmReceipt({ id: item.id })
        if(!success) return
        this.$toast(data.msg)
        console.log(data)
        await this.apiGetTotalOrderHandel()
        this.resetData()
      },

      // 确认收货成功触发
      async emitConfirmReceive() {
        await this.apiGetTotalOrderHandel()
        this.resetData()
      },

    },
    computed: {
      // 头部导航栏名称
      titleName() {
        if(this.routeType == '0') return '未完成垫付任务'
        if(this.routeType == '1') return '未完成浏览任务'
        return '未完成预售任务'
      },
      // 计算订单数量
      computeTotalTaskObj() {
        return {
          // 全部
          total: this.totalTaskObj.s10 + this.totalTaskObj.s20 + this.totalTaskObj.s30 + this.totalTaskObj.s40 + this.totalTaskObj.s50,
          // 待操作
          waitOprate: this.totalTaskObj.s10 + this.totalTaskObj.s20 + this.totalTaskObj.s30,
          // 带返款
          waitMoney: this.totalTaskObj.s40,
          // 待收货
          waitReceive: this.totalTaskObj.s50
        }
      },
      // 头部tab列表
      tabList() {
        if(this.routeType == '0') return [{ name: 'ALL', title: '全部', number: this.computeTotalTaskObj.total }, { name: 1, title: '待操作', number: this.computeTotalTaskObj.waitOprate }, { name: 2, title: '待返款' , number: this.computeTotalTaskObj.waitMoney }, { name: 3, title: '待收货', number: this.computeTotalTaskObj.waitReceive}]
        if(this.routeType == '1') return [{ name: 'ALL', title: '全部', number: this.computeTotalTaskObj.total }, { name: 1, title: '待操作', number: this.computeTotalTaskObj.waitOprate }]
        if(this.routeType == '2') return [{ name: 'ALL', title: '全部', number: this.computeTotalTaskObj.total }, { name: 1, title: '待操作', number: this.computeTotalTaskObj.waitOprate }, { name: 2, title: '待返款', number: this.computeTotalTaskObj.waitMoney }, { name: 3, title: '待收货', number: this.computeTotalTaskObj.waitReceive}]
        return []
      }
    }
  }
</script>

<style lang="scss">
.v_task_undone {
  height: 100%;
  background: #F1F1F1;
  .v-task-undone-container {
    flex: 1;
    overflow: hidden;
    position: relative;
    .van-tabs {
      height: 100%;    
      display: flex;
      flex-direction: column;
      .van-tabs__content {
        flex: 1;
        overflow: auto;
        .v-task-undone-list {
          padding: 0 10px;
          .v-task-undone-item {
            background: $white;
            border-radius: 8px;
            margin-top: 10px;
            padding: 15px 12px;
            .v-task-undone-item-top {
              .v-task-undone-item-top-left {
                flex: 1;
                .iconfont {
                  font-size: 22px;
                  color: $main_color;
                }
                .v-task-undone-item-top-left-store {
                  font-size: 15px;
                  padding-left: 6px;
                  color: #000000;
                }
              }
              .v-task-undone-item-top-right {
                .v-task-undone-item-top-right-staus {
                  font-size: 15px;
                  color: #FA436A
                }
              }
            }

            .v-task-undone-item-middle {
              padding-top: 14px;
              .v-task-undone-item-middle-left {
                img {
                  width: 80px;
                  height: 80px;
                  border-radius: 8px;
                  object-fit: cover;
                }
              }
              
              .v-task-undone-item-middle-right {
                flex: 1;
                padding-left: 18px;
                .v-task-undone-item-middle-right-top {
                  .v-task-undone-item-middle-right-top-name {
                    flex: 1;
                    width: 150px;
                    font-size: 14px;
                    padding-right: 10px;
                    @include textManyOverflow(2);
                    span {
                      flex: 1;
                    }
                  }
                  .v-task-undone-item-middle-right-top-yong {
                    font-size: 12px;
                    color: $main_color;
                    span {
                      font-weight: bold;
                      color: $main_color;
                      font-size: 20px;
                      padding-left: 5px;
                      line-height: 1;
                    }
                  }
                }

                .v-task-undone-item-middle-right-middle {
                  padding-top: 8px;
                  font-size: 13px;
                  color: #A0A0A0;
                  .v-task-undone-item-middle-right-top-dan {
                    margin-top: 4px;
                    color: #A0A0A0;
                    font-size: 14px;
                  }
                  .v-task-undone-item-middle-right-middle-dian {
                    color: #A0A0A0;
                    font-size: 12px;
                    span {
                      font-size: 16px;
                      padding-left: 5px;
                    }
                  }
                  .v-task-undone-item-middle-right-middle-type {
                    font-size: 13px;
                    font-weight: bold;
                    padding-top: 4px;
                    span {
                      line-height: 1;
                      padding: 4px 10px;
                      border-radius: 14px;
                      font-size: 12px;
                      color: $main_color;
                      border: 1px solid #FA436A;
                      font-weight: bold;
                    }
                  }
                }
              }
            }

            .v-task-undone-item-line {
              width: 100%;
              height: 1px;
              background: #DCDCDC;
              margin-top: 12px;
            }

            .v-task-undone-item-bottom {
              .v-task-undone-item-bottom-stop-time {
                text-align: right;
                padding-top: 12px;
                font-size: 13px;
                color: #A0A0A0;
                &.v-task-undone-item-bottom-stop-time-second {
                  color: $main_color;
                }
              }
              .v-task-undone-item-bottom-button {
                padding-top: 10px;
                .v-task-undone-item-bottom-button-item {
                  margin-left: 14px;
                  width: 92px;
                  height: 28px;
                  border-radius: 15px;
                  border: 1px solid #E5E5E5;
                  font-size: 13px;
                  color: #828282;
                  &.v-task-undone-btn-operation {
                    border: 1px solid #FA436A;
                    color: #FA436A;
                  }
                }
              }
            }
          }
        }
      }
    }

  }
}
</style>
<template>
  <div class="s_task_undone_con_rec_pop">
    <van-popup v-model="show" :safe-area-inset-bottom="true" :style="{ width: '80%', height: 'auto' }" class="s-task-undone-con-rec-container">
      <!-- 上传 -->
      <div class="s-task-details-bottom-upload g-flex-column">
        <p class="s-task-details-bottom-upload-title">上传截图:</p>
        <div class="s-task-details-bottom-upload-list g-flex-align-center">
          <div class="s-task-details-bottom-upload-list-item g-flex-column g-flex-align-center g-flex-1">
            <task-details-bottom-upload :imgUrl.sync="form.receive_goods_img" />
            <span class="s-task-details-bottom-upload-list-item-title">确认收货截图</span>
          </div>
          <div class="s-task-details-bottom-upload-list-item g-flex-column g-flex-align-center g-flex-1">
            <task-details-bottom-upload :imgUrl.sync="form.comment_img" />
            <span class="s-task-details-bottom-upload-list-item-title">评价截图</span>
          </div>
        </div>
      </div>

      <div class="s-task-undone-con-rec-submit g-flex-align-center">
        <div class="s-task-undone-con-rec-submit-cancel">
          <operation-button :buttonObj="buttonObjSecond" @clickItem="emitClickItem"/>
        </div>
        <div class="s-task-undone-con-rec-submit-yes">
          <operation-button :buttonObj="buttonObj" @clickItem="emitClickItem"/>
        </div>
        
      </div>
    </van-popup>
  </div>
</template>

<script>
import TaskDetailsBottomUpload from '@/components/TaskDetailsBottomUpload.vue'
import OperationButton from '@/components/OperationButton.vue'
import { apiConfirmReceipt } from '@/utils/api.js'
export default {
  components: { TaskDetailsBottomUpload, OperationButton },
  data() {
    return {
      show: false,
      form: {
        id: '',
        receive_goods_img: '', //收货截图
        comment_img: '' // 评价截图
      },
      buttonObj: {
        claSS: 'c-btn-default ',
        title: '确认提交',
        event: 'apiConfirmReceiptHandel'
      },
      buttonObjSecond: {
        claSS: 'c-btn-cancel c-btn-text-color-white',
        title: '取消',
        event: 'onClose'
      }
    }
  },
  methods: {
    emitClickItem(event) {
      if(!event) return
      this[event]()
    },
    onShow(obj) {
      this.form.id = obj.id
      this.show = true
    },

    onClose() {
      this.form.receive_goods_img = ''
      this.form.comment_img = ''
      this.show = false 
    },
    // 确认收货
    async apiConfirmReceiptHandel() {
      if(!this.form.receive_goods_img) return this.$toast('收货截图不能为空')
      if(!this.form.comment_img) return this.$toast('评价截图不能为空')
      this.$global.loadingShow = true
      const { success, data } = await apiConfirmReceipt(this.form)
      if(!success) return
      this.$toast(data.msg)
      console.log(data)
      this.$emit('emitConfirmReceive')
      this.onClose()
    }
  }
}
</script>

<style lang='scss'>
.s_task_undone_con_rec_pop {
  .s-task-undone-con-rec-container {
    padding: 20px;
    border-radius: 8px;
  }
  .s-task-details-bottom-upload {
    padding: 0 16px 0px 8px;
    .s-task-details-bottom-upload-title {
      padding: 10px 0 30px 0;
      font-size: 16px;
      color: #323233;
      font-weight: bold;
    }
    .s-task-details-bottom-upload-list {
      .s-task-details-bottom-upload-list-item {
        .s-task-details-bottom-upload-list-item-title {
          color: $grey;
          font-size: 16px;
          padding-top: 10px;
        }
      }
    }
  }
  .s-task-undone-con-rec-submit {
    margin-top: 30px;
    .s-task-undone-con-rec-submit-cancel {
      flex: 2;
    }
    .s-task-undone-con-rec-submit-yes {
      flex: 3;
    }
  }
}

</style>